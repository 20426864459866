import "@/styles/globals.css";
import Router, { useRouter } from "next/router";
import ProgressBar from "@badrap/bar-of-progress";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { ToastProvider } from "@/components/Toast/ToastProvider";
import MenuProvider from "@/components/Menu/MenuProvider";
import * as gtag from "@/lib/gtag";
import WishlistProvider from "@/modules/Wishlist/WishlistProvider";
import { Inter } from "next/font/google";
import { SalesforceProvider } from "hooks/useSalesforce";
import UsercentricsProvider from "@/components/Usercentrics/UsercentricsProvider";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";
import { Analytics } from "@vercel/analytics/react";

const inter = Inter({
  subsets: ["latin"],
  display: "swap",
});
export type NextPageWithLayout<T> = NextPage<T> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout<any>;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_FRONTEND_URL;

  const progress = new ProgressBar({
    size: 2,
    color: "#ff7d00",
    className: "bar-of-progress",
    delay: 100,
  });

  Router.events.on("routeChangeStart", progress.start);
  Router.events.on("routeChangeComplete", progress.finish);
  Router.events.on("routeChangeError", progress.finish);

  useIsomorphicLayoutEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
      if (
        !pageProps ||
        !pageProps.pageProps ||
        !pageProps.pageProps.meta ||
        !pageProps.pageProps.meta.title ||
        !pageProps.pageProps.slug
      )
        return;

      gtag.event({
        event: "virtualPageView",
        pageTitle: `${pageProps.pageProps.meta?.title}`,
        pageURL: `${baseUrl}${pageProps.pageProps?.slug}`,
        page: `${pageProps.pageProps.slug}`,
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const getLayout = Component.getLayout || ((page) => page);

  const JSXComponent = Component as any;

  return getLayout(
    <>
      <link rel="preconnect" href={process.env.NEXT_PUBLIC_API_URL} />
      <link rel="preconnect" href="//app.usercentrics.eu" />
      <link rel="preconnect" href="//api.usercentrics.eu" />
      <link rel="preconnect" href="//aggregator.service.usercentrics.eu" />
      <link rel="preconnect" href="//graphql.usercentrics.eu" />
      <link
        rel="preconnect"
        href="https://consent-api.service.consent.usercentrics.eu"
      />
      {/* eslint-disable-next-line @next/next/next-script-for-ga */}
      <script
        type="text/plain"
        defer
        data-usercentrics="Google Tag Manager"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GA_ID}');`,
        }}
      />

      <script
        type="text/plain"
        data-usercentrics="Google Tag Manager"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      <script
        type="text/plain"
        src="https://510005835.collect.igodigital.com/collect.js"
        data-usercentrics="Salesforce Marketing Cloud"
        id="salesforce"
        defer
      />

      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <UsercentricsProvider>
        <SalesforceProvider
          orgId={`${process.env.NEXT_PUBLIC_SALESFORCE_ORG_ID}`}
        >
          <ToastProvider>
            <MenuProvider>
              <WishlistProvider>
                <JSXComponent {...pageProps} />
                <SpeedInsights sampleRate={0.5} />
                <Analytics />
              </WishlistProvider>
            </MenuProvider>
          </ToastProvider>
        </SalesforceProvider>
      </UsercentricsProvider>
    </>
  );
}
