import { createContext, useState } from "react";

export const MenuContext = createContext({
  filterOpenState: false,
  changeFilterOpenState: (open: boolean) => {},
});

const MenuProvider = ({ children }) => {
  const [filterOpenState, setFilterOpenState] = useState(false);

  const changeFilterOpenState = (open: boolean) => {
    setFilterOpenState(open);
  };

  return (
    <MenuContext.Provider value={{ filterOpenState, changeFilterOpenState }}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
