import * as ToastPrimitive from "@radix-ui/react-toast";
import React, { useCallback } from "react";
import { useState } from "react";
import { Toast } from "./Toast";

type Toast = {
  id?: number | string;
  title?: string;
  description?: string;
  variant?: "info" | "warning" | "alert" | "success";
  action?: () => void;
};

export const ToastContext = React.createContext({
  addToast: (content: Toast) => {},
  removeToast: (content: Toast) => {},
});

let id = 1;

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    (content: Toast) => {
      setToasts((toasts: any[]) => [
        ...toasts,
        {
          id: id++,
          ...content,
        },
      ]);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts]
  );

  const toast = toasts[toasts.length - 1];

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}
    >
      {children}
      <ToastPrimitive.Provider swipeDirection="right">
        <ToastPrimitive.Viewport className="fixed bottom-0 left-0 right-0 w-full md:top-0 lg:bottom-none z-[999]">
          {toast && (
            <Toast
              description={toast.description}
              title={toast.title}
              variant={toast.variant}
            />
          )}
        </ToastPrimitive.Viewport>
      </ToastPrimitive.Provider>
    </ToastContext.Provider>
  );
};
