import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";
import { createContext, useState } from "react";
const getCartId = () => {
  const cartId =
    typeof localStorage !== "undefined" && localStorage.getItem("wishlist")
      ? JSON.parse(localStorage.getItem("wishlist")!).cartId
      : "";
  return cartId;
};

export const fetchWishlist = async (cartId?: string) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}cart?cartId=${cartId}`
  );

  if (response.ok) {
    const { products, name = "Merkliste" } = await response.json();
    return { products, name, cartId };
  }
  return { products: [], name: "Merkliste" };
};

export const WishlistContext = createContext<{
  saved: string[];
  addToWishlist: (carId: number) => Promise<void>;
  removeFromWishlist: (carId: number) => Promise<void>;
  isOnWishlist: (carId: number) => boolean;
  setWishlistName: (name: string) => Promise<void>;
  resetWishlist: () => Promise<void>;
  count: number;
  cartId: string;
}>({
  cartId: "",
  saved: [],
  addToWishlist: async (carId) => {},
  removeFromWishlist: async (carId) => {},
  isOnWishlist: (carId) => false,
  setWishlistName: async () => {},
  resetWishlist: async () => {},
  count: 0,
});

const WishlistProvider = ({ children }) => {
  const [saved, setSaved] = useState<any[]>([]);

  useIsomorphicLayoutEffect(() => {
    getWishlist();
  }, []);

  const cartId = getCartId();

  const getWishlist = async () => {
    const cartId = getCartId();

    if (!cartId) return;
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}cart/?cartId=${cartId}`
    );
    return syncWishlist(response);
  };

  const addToWishlist = async (carId: number) => {
    const cartId = getCartId();

    setWishlist({
      cartId,
      products: [...saved, { uid: carId }],
      amount: saved.length + 1,
    });
    return fetch(`${process.env.NEXT_PUBLIC_API_URL}cart/`, {
      method: "POST",
      body: JSON.stringify({
        cartId,
        carId,
      }),
    })
      .then((response) => {
        return syncWishlist(response);
      })
      .catch((e) => console.log(e));
  };

  const removeFromWishlist = async (carId: number) => {
    const cartId = getCartId();
    setWishlist({
      cartId,
      products: saved.filter((uid) => uid !== carId),
      amount: saved.length - 1,
    });
    return fetch(`${process.env.NEXT_PUBLIC_API_URL}cart/`, {
      method: "DELETE",
      body: JSON.stringify({
        carId,
        cartId,
      }),
    })
      .then((response) => {
        return syncWishlist(response);
      })

      .catch((e) => console.log(e));
  };

  const resetWishlist = async () => {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}cart/reset/?cartId=${cartId}`,
      { method: "GET" }
    );

    if (response.ok) {
      const { cartId } = await response.json();
      setWishlist({
        cartId: cartId,
        products: [],
        amount: 0,
        name: "",
      });
      setSaved([]);
    }
  };
  const setWishlistName = (name: string) => {
    const cartId = getCartId();
    return fetch(`${process.env.NEXT_PUBLIC_API_URL}cart/`, {
      method: "POST",
      body: JSON.stringify({ name, cartId }),
    })
      .then((response) => {
        return syncWishlist(response);
      })
      .catch((e) => console.log(e));
  };

  const isOnWishlist = (carId: number) => {
    return saved?.some(({ uid }) => uid === carId);
  };

  const syncWishlist = async (response: Response) => {
    if (response.ok) {
      const { products, cartId, amount, name } = await response.json();
      setWishlist({ products, cartId, amount, name });

      return products;
    }

    return [];
  };

  const setWishlist = ({ amount, cartId, products, name = "" }) => {
    setSaved(products);
    localStorage.setItem(
      "wishlist",
      JSON.stringify({
        cartId,
        amount,
        products: products.map(({ uid }) => uid),
        name,
      })
    );
  };

  return (
    <WishlistContext.Provider
      value={{
        saved,
        cartId,
        addToWishlist,
        removeFromWishlist,
        isOnWishlist,
        setWishlistName,
        resetWishlist,
        count: saved?.length || 0,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistProvider;
