export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (!(window.gtag as any)) return;
  (window.gtag as any)("config", process.env.NEXT_PUBLIC_GA_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (config) => {
  (window as any).dataLayer?.push(config);
};
export function resetDatalayer() {
  (window as any).dataLayer = [];
}
