import { Category } from "@usercentrics/cmp-browser-sdk";
import { createContext, Dispatch, SetStateAction, useState } from "react";

export const UsercentricsContext = createContext<{
  categories: Category[];
  setCategories: Dispatch<SetStateAction<Category[]>>;
}>({ categories: [], setCategories: () => {} });

const UsercentricsProvider = ({ children }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  return (
    <UsercentricsContext.Provider value={{ categories, setCategories }}>
      {children}
    </UsercentricsContext.Provider>
  );
};

export default UsercentricsProvider;
